import { Box, BoxProps, Text } from "@chakra-ui/core";
import React from "react";
import BodyText from "../components/BodyText";
import SocialIcons from "../components/SocialIcons";
import Container from "./Container";

interface Props {
  title: string;
  paddingTop: number;
  paddingBottom: number;
  backgroundColor: string;
  description: {
    description: string;
  };
}

const Header: React.FC<Props & BoxProps> = ({
  title,
  description,
  paddingTop,
  paddingBottom,
  backgroundColor,
  ...props
}) => {
  const bg = backgroundColor != null ? backgroundColor : "white";
  const pt = paddingTop != null ? paddingTop : 24;
  const pb = paddingBottom != null ? paddingBottom : 24;
  return (
    <Box
      textAlign="center"
      bg={bg}
      pt={[pt / 2, pt / 2, pt, pt]}
      pb={[pb / 2, pb / 2, pb, pb]}
      {...props}
    >
      <Container>
        <Text
          lineHeight={1}
          fontWeight="semibold"
          fontSize="5xl"
          as="h2"
          mb={4}
        >
          {title}
        </Text>
        {description && <BodyText>{description.description}</BodyText>}
        <SocialIcons display="none" iconSize="50px" justifyContent="center" />
      </Container>
    </Box>
  );
};

export default Header;
