import { Box, Image, Link } from "@chakra-ui/core";
import React from "react";
import Container from "./Container";

interface Props {
  title: string;
  showTitle: boolean;
  link?: string;
  image: {
    fixed: {
      src: string;
    };
  };
  styleProps: any;
}

const Infographic: React.FC<Props> = ({ image, link, styleProps }) => {
  return (
    <Link href={link}>
      <Box py={24} {...styleProps}>
        <Container>
          <Image mx="auto" src={image.fixed.src} />
        </Container>
      </Box>
    </Link>
  );
};

export default Infographic;
