import { Box, Flex, Link } from "@chakra-ui/core";
import React from "react";
import BodyText from "./BodyText";
import HeaderText from "./HeaderText";

interface Props {}

const ARFrog: React.FC<Props> = () => {

  return (

    <Box 
      position="relative" 
      marginLeft="calc(-50vw + 50%)"
      marginRight="calc(-50vw + 50%)"
      padding="7.5%"
      background="#efefef"
    >
    <Flex
      justifyContent="center"
      flexDirection={["column", "column", "column", "column", "row"]}
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      textAlign = "left"
    >
      <Box 
        order={["1", "1", "1", "1", "2"]}
        width={['100%', '100%', '100%', '100%', 0.65]}
        height={['50vh', '50vh', '50vh', '65vh', '65vh']}
      >
      <iframe src="https://d2pifd398unoeq.cloudfront.net/ar-frog-2021.html" width="100%" height="100%" style={{border: "6px double #fff"}}></iframe>
      </Box>
      <Box 
        order={["2", "2", "2", "2", "1"]}
        width={['100%', '100%', '100%', '100%', 0.3]}
        maxWidth="800px"
        pt={10}
      >
        <HeaderText title='Hey, look!' />
        <BodyText> We're excited to share "Bella" in 3D with you! He is our very own Augmented Reality (AR) frog: the Cape York Graceful Tree Frog (<em>Litoria bella</em>). <br/>
        Back in 2016, our lead scientist Dr Jodi Rowley helped identify this frog as Australia’s 239th frog species.
        You can have fun with our AR frog by using our <Link color="primary.orange" href="https://www.instagram.com/ar/648181995898605/">Instagram effects tab</Link> and don’t forget to tag @frogidaus in any image posts, we look forward to seeing the awesome places you’re using the FrogID app and Bella!
        </BodyText>
      </Box>
    </Flex>
  </Box>

  );
};

export default ARFrog;
