import { Flex, Image, Link } from "@chakra-ui/core";
import React from "react";
import PrimaryButton from "./PrimaryButton";

interface Props {}

const AppDownloadButtons: React.FC<Props> = () => {
  const mb = [3, 3, 0, 0];
  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      justifyContent={["center", "center", "flex-start", "flex-start"]}
      flexDirection="row"
      mt={[6, 6, 12, 12]}
    >
      <PrimaryButton mr={3} mb={mb} size="lg" bg="primary.orange">
        Get Involved
      </PrimaryButton>
      <Link
        mr={3}
        mb={mb}
        href="https://play.google.com/store/apps/details?id=au.net.australianmuseum.frogid"
      >
        <Image height="51px" src="/icon-appstore.png" />
      </Link>
      <Link
        mr={3}
        mb={mb}
        href="https://apps.apple.com/au/app/frogid/id1176329797"
      >
        <Image height="51px" src="/icon-google-play.png" />
      </Link>
    </Flex>
  );
};

export default AppDownloadButtons;
