import { useEffect, useState } from "react";

const useStyleSheet = (url: string) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const link = document.createElement("link");

    link.href = url;
    link.rel = "stylesheet";
    link.onload = () => setLoaded(true);

    document.body.appendChild(link);

    return () => {
      document.body.removeChild(link);
    };
  }, [url]);

  return loaded;
};

export default useStyleSheet;
