import { Stack } from "@chakra-ui/core";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { IconLink } from "./SocialIcons";

interface Props {}

const SocialMediaIcons: React.FC<Props> = () => {
  return (
    <Stack justifyContent="center" mt={6} isInline spacing={5}>
      <IconLink
        fontSize="3rem"
        href="https://www.facebook.com/FrogIDAus"
        icon={faFacebookSquare}
      />
      <IconLink
        fontSize="3rem"
        href="https://twitter.com/FrogIDAus"
        icon={faTwitterSquare}
      />
      <IconLink
        fontSize="3rem"
        href="https://instagram.com/FrogIDAus"
        icon={faInstagramSquare}
      />
    </Stack>
  );
};

export default SocialMediaIcons;
