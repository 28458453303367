import React, { useEffect } from "react";
import useScript from "../hooks/useScript";
import useStyleSheet from "../hooks/useStyleSheet";

interface Props {
  title: string;
  cloudinaryVideoId?: string;
}

const CloudinaryVideoPlayer: React.FC<Props> = ({cloudinaryVideoId}) => {
  const clLoaded = useScript(
    "https://unpkg.com/cloudinary-core@2.6.3/cloudinary-core-shrinkwrap.min.js"
  );
  const videoPlayerLoaded = useScript(
    "https://unpkg.com/cloudinary-video-player@1.3.4/dist/cld-video-player.min.js"
  );
  const styleLoaded = useStyleSheet(
    "https://unpkg.com/cloudinary-video-player@1.3.4/dist/cld-video-player.min.css"
  );

  useEffect(() => {
    if (styleLoaded && clLoaded && videoPlayerLoaded) {
      const cld = (window as any).cloudinary.Cloudinary.new({
        cloud_name: "ausmus"
      });
      try {
        cld.videoPlayer("video-player");
      } catch {}
    }
  }, [styleLoaded, clLoaded, videoPlayerLoaded]);

  return (
    <video
      style={{
        width: "100%",
        height: "648px"
      }}
      id="video-player"
      controls
      data-cld-public-id={cloudinaryVideoId}
      className="video-player"
    />
  );
};

export default CloudinaryVideoPlayer;
