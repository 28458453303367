import { Box, BoxProps, Text } from "@chakra-ui/core";
import React from "react";

interface Props {
  title: string;
  caption?: string;
}

const PrimaryHeader: React.FC<Props & BoxProps> = ({
  title,
  caption,
  ...props
}) => {
  return (
    <Box {...props}>
      <Text lineHeight={1} fontWeight={800} fontSize="5xl" as="h1" mb={5}>
        {title}
      </Text>
      {caption && (
        <Text fontSize="22px" as="p">
          {caption}
        </Text>
      )}
    </Box>
  );
};

export default PrimaryHeader;
