import { Box } from "@chakra-ui/core";
import React, { useEffect, useRef } from "react";
import Container from "./Container";

interface Props {
  title: string;
  code: {
    code: string;
  };
}

const CodeSnippet: React.FC<Props> = ({ title, code }) => {
  const containerRef = useRef<{
    innerHTML: string;
    appendChild: (snippet: DocumentFragment) => void;
  }>({
    innerHTML: "",
    appendChild: () => {}
  });

  useEffect(() => {
    const snippet = document.createRange().createContextualFragment(code.code);
    containerRef.current.innerHTML = "";
    containerRef?.current.appendChild(snippet);
    console.log(containerRef.current, snippet);
  }, [containerRef, code.code]);

  return (
    <Container py={24}>
      <Box ref={containerRef} />
    </Container>
  );
};

export default CodeSnippet;
