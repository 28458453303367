import { Flex, Spinner } from "@chakra-ui/core";
import React from "react";
import { useData } from "../hooks/useData";
import HighlightStat from "./HighlightStat";

interface Props {}

const FrogIDWeekHighlights2021: React.FC<Props> = () => {
  const url = "/api/user/leaderboards/frogid_week_2021";
  const { data } = useData(url);

  if (data == null) return <Spinner m={10} color="primary.orange" />;
  const {
    captures_count,
    published_frogs_count,
    published_species_count
  } = data[url];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={["column", "column", "row", "row"]}
      flexWrap="wrap"
      my={12}
    >
      <HighlightStat title="Calls Submitted" count={captures_count} />
      <HighlightStat title="Verified Frogs" count={published_frogs_count} />
      <HighlightStat title="Species Found" count={published_species_count} />
    </Flex>
  );
};

export default FrogIDWeekHighlights2021;
