import { Box } from "@chakra-ui/core";
import React from "react";
import HorizontalContent from "../components/HorizontalContent";
import Container from "./Container";

interface Props {
  title?: string;
  description: {
    childMarkdownRemark: {
      html: string
    }
  };
  imagePlacement: "Left" | "Right";
  image?: {
    fixed: {
      src: string;
    };
  };
  linkLabel?: string;
  linkUrl?: string;
  paddingTop: number;
  paddingBottom: number;
  backgroundColor: string;
  styleProps?: any;
}

const Information: React.FC<Props> = ({
  image,
  description,
  imagePlacement,
  paddingTop,
  paddingBottom,
  backgroundColor,
  ...props
}) => {
  const pt = paddingTop != null ? paddingTop : 24;
  const pb = paddingBottom != null ? paddingBottom : 24;
  const bg = backgroundColor != null ? backgroundColor : "white";
  return (
    <Box pt={[pt / 2, pt / 2, pt, pt]} pb={[pb / 2, pb / 2, pb, pb]} bg={bg}>
      <Container>
        <HorizontalContent
          image={image?.fixed.src}
          imageAlignment={imagePlacement}
          description={description.childMarkdownRemark.html}
          {...props}
        />
      </Container>
    </Box>
  );
};

export default Information;
