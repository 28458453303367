import { Box } from "@chakra-ui/core";
import React from "react";

interface Props {
  title: string;
  youTubeEmbedUrl?: string;
}

const YouTubeVideoPlayer: React.FC<Props> = ({ youTubeEmbedUrl }) => {
  return (
    <Box position="relative" pb="56.25%" overflow="hidden">
      <iframe
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "100%",
          width: "100%"
        }}
        src={youTubeEmbedUrl}
        frameBorder="0"
        allowFullScreen
      />
    </Box>
  );
};

export default YouTubeVideoPlayer;
