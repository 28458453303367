import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from "@chakra-ui/core";
import React from "react";
import Container from "./Container";
import ContentfulRichText from "./ContentfulRichText";

interface Props {
  title: string;
  faqQuestions: {
    title: string;
    content: {
      json: string;
    };
  }[];
}

const FAQ: React.FC<Props> = ({ title, faqQuestions }) => {
  return (
    <Container>
      <Box>
        <Text fontSize="3xl" fontWeight="semibold">
          {title}
        </Text>
      </Box>
      <Accordion my={12} defaultIndex={0}>
        {faqQuestions.map(question => (
          <AccordionItem border="none" mb={2}>
            <AccordionHeader
              rounded="lg"
              color="gray.800"
              fontWeight="semibold"
              fontSize="2xl"
              bg="bg.grayAlt"
              _expanded={{
                roundedBottomLeft: "none",
                roundedBottomRight: "none"
              }}
              _hover={{
                bg: "bg.grayAlt"
              }}
            >
              <Box flex="1" textAlign="left">
                {question.title}
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel px={8} pb={6} rounded="lg" shadow="lg">
              <ContentfulRichText content={question.content} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  );
};

export default FAQ;
