import { graphql } from "gatsby";
import React from "react";
import Page from "../components/Page";

interface Props {
  data: {
    contentfulPage: any;
  };
}

const PageTemplate: React.FC<Props> = ({ data }) => {
  return <Page contentfulPage={data.contentfulPage} />;
};

export const query = graphql`
  query ContentfulPage($slug: String) {
    contentfulPage(slug: { eq: $slug }) {
      ...Page
    }
  }

  fragment Page on ContentfulPage {
    id
    metaTitle
    metaDescription
    sections {
      ...Hero
      ...Header
      ...InstructionRow
      ...Information
      ...Infographic
      ...CodeSnippet
      ...RichText
      ...VideoPlayer
      ...CustomComponent
      ...FAQ
      ...FrogIdWeek
    }
  }

  fragment FrogIdWeek on ContentfulFrogIdWeek {
    id
    heroText {
      json
    }
    heroImage {
      fixed(width: 800) {
        src
      }
    }
    heroBkg {
      fixed(width: 2200) {
        src
      }
    }
    startDate
    endDate
  }

  fragment CustomComponent on ContentfulCustomComponent {
    id
    paddingTop
    paddingBottom
    backgroundColor
    description {
      json
    }
    type
  }

  fragment Hero on ContentfulHero {
    id
    title
    description {
      description
    }
    callToAction {
      ...RichText
    }
    image {
      fixed(width: 800) {
        src
      }
    }
    instructionRow {
      ...Instruction
    }
  }

  fragment Header on ContentfulHeader {
    id
    title
    description {
      description
    }
    paddingTop
    paddingBottom
    backgroundColor
  }

  fragment Instruction on ContentfulInstruction {
    id
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    buttonLabel
    buttonUrl
    image {
      fixed(width: 800) {
        src
      }
    }
  }

  fragment InstructionRow on ContentfulInstructionRow {
    id
    title
    columns
    description {
      description
    }
    instructions {
      ...Instruction
    }
  }

  fragment Information on ContentfulInformation {
    id
    title
    paddingTop
    paddingBottom
    backgroundColor
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      fixed(width: 800) {
        src
      }
    }
    imagePlacement
    linkLabel
    linkUrl
  }

  fragment Infographic on ContentfulInfographic {
    id
    title
    showTitle
    link
    image {
      fixed(width: 1400) {
        src
      }
    }
    styleProps {
      textAlign
      py
    }
  }

  fragment CodeSnippet on ContentfulCodeSnippet {
    id
    title
    code {
      code
    }
  }

  fragment RichText on ContentfulRichTextBlock {
    id
    title
    paddingTop
    paddingBottom
    backgroundColor
    alignment
    image {
      fixed(width: 800) {
        src
      }
    }
    content {
      json
    }
  }

  fragment VideoPlayer on ContentfulVideoPlayer {
    id
    title
    source
    youTubeEmbedUrl
    cloudinaryVideoId
  }

  fragment FAQ on ContentfulFaq {
    id
    title
    faqQuestions {
      title
      content {
        json
      }
    }
  }
`;

export default PageTemplate;
