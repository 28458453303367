import { Flex, Text } from "@chakra-ui/core";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Avatar from "./Avatar";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "./DataTable";
import TableStat from "./TableStat";

interface Props {
  data: {
    id: string;
    name: string;
    avatar: string;
    count: number;
  }[];
}

const LeaderboardTable: React.FC<Props> = ({ data }) => {
  return (
    <Table my={12}>
      <TableHeader>
        <TableHeaderCell width={[16, 16, 24, 32]}>Rank</TableHeaderCell>
        <TableHeaderCell width={[16, 16, 24, 32]}></TableHeaderCell>
        <TableHeaderCell textAlign="left" flex="1">
          Name
        </TableHeaderCell>
        <TableHeaderCell width={["5rem", "5rem", "10rem", "18rem"]}>
          Captures Count
        </TableHeaderCell>
      </TableHeader>
      {data.map(
        (
          group: { id: string; name: string; avatar: string; count: number },
          index: number
        ) => (
          <TableRow key={group.id}>
            <TableCell width={[16, 16, 24, 32]}>{index + 1}</TableCell>
            <TableCell width={[16, 16, 24, 32]}>
              <Avatar width="40px" height="40px" src={group.avatar} />
            </TableCell>
            <TableCell textAlign="left" flex="1">
              <Flex alignItems="center">
                {group.name}{" "}
                {index === 0 && (
                  <Text ml={2} fontSize="25px" color="primary.orange">
                    <FontAwesomeIcon icon={faMedal} />
                  </Text>
                )}
              </Flex>
            </TableCell>
            <TableCell width={["5rem", "5rem", "10rem", "18rem"]}>
              <TableStat count={group.count} />
            </TableCell>
          </TableRow>
        )
      )}
    </Table>
  );
};

export default LeaderboardTable;
