import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  PseudoBox,
  Select,
  Stack,
} from "@chakra-ui/core";
import { graphql, Link, useStaticQuery } from "gatsby";
import { capitalize } from "lodash";
import React, { useReducer } from "react";
import Avatar from "./Avatar";

interface Props {}

const FrogFactSheets: React.FC<Props> = () => {
  const {
    allFrog: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allFrog(sort: { fields: scientific_name }) {
          nodes {
            id
            slug
            scientificName: scientific_name
            commonNames: common_names
            coverPhoto: cover_photo
            thumb
            states
            habitats
          }
        }
      }
    `
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const { searchQuery, habitatFilter, stateFilter } = state;
  const { habitats, states } = getHabitatsAndStates(nodes);
  const frogs = filterFrogs(nodes, searchQuery, habitatFilter, stateFilter);

  return (
    <Box>
      <Stack
        isInline
        spacing={[0, 0, 6, 6]}
        flexWrap="wrap"
        justifyContent="center"
      >
        <FormControl mb={[3, 3, 3, 0]}>
          <FormLabel fontSize="lg" fontWeight="semibold">
            Search by name
          </FormLabel>
          <Input
            width="64"
            placeholder="Scientific/common name"
            type="search"
            id="name"
            aria-describedby="name-helper-text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch({
                type: "search",
                keyword: e.target.value,
              });
            }}
          />
        </FormControl>
        <FormControl mb={[3, 3, 3, 0]}>
          <FormLabel fontSize="lg" fontWeight="semibold">
            Filter by habitat
          </FormLabel>
          <Select
            width="64"
            id="habitat"
            onChange={(e) => {
              dispatch({
                type: "filterByHabitat",
                habitat: e.target.value,
              });
            }}
          >
            <option value="">-</option>
            {habitats.map((habitat) => (
              <option key={habitat} value={habitat}>
                {capitalize(habitat)}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl mb={[3, 3, 3, 0]}>
          <FormLabel fontSize="lg" fontWeight="semibold">
            Filter by state
          </FormLabel>
          <Select
            width="64"
            id="state"
            onChange={(e) => {
              dispatch({
                type: "filterByState",
                state: e.target.value,
              });
            }}
          >
            <option value="">-</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state.toUpperCase()}
              </option>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Box my={5}>{frogs.length} results</Box>
      <Stack isInline spacing={[0, 0, 3, 3]} flexWrap="wrap">
        {frogs.map((frog) => (
          <PseudoBox
            key={frog.id}
            borderColor="gray.300"
            borderWidth="1px"
            rounded="lg"
            width={["100%", "100%", "17rem", "17rem"]}
            p={3}
            mb={3}
            _hover={{
              shadow: "md",
            }}
          >
            <Link to={`/frogs/${frog.slug}`}>
              <Flex alignItems="center">
                <Box mr={6}>
                  <Avatar width="70px" height="70px" src={frog.thumb} />
                </Box>
                <Box>
                  <Box
                    lineHeight={1.0}
                    mb={1}
                    fontSize="lg"
                    fontStyle="italic"
                    fontWeight="semibold"
                  >
                    {frog.scientificName}
                  </Box>
                  <Box color="gray.500" lineHeight={1.0}>
                    {frog.commonNames[0]}
                  </Box>
                </Box>
              </Flex>
            </Link>
          </PseudoBox>
        ))}
      </Stack>
    </Box>
  );
};

export default FrogFactSheets;

interface Frog {
  id: string;
  slug: string;
  scientificName: string;
  commonNames: string[];
  states: string[];
  habitats: string[];
  coverPhoto: string;
  thumb: string;
}

type State = any;
type Action =
  | { type: "search"; keyword: string }
  | { type: "filterByHabitat"; habitat: string }
  | { type: "filterByState"; state: string };

const initialState: State = {
  searchQuery: "",
  habitatFilter: "",
  stateFilter: "",
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "search":
      return {
        ...state,
        searchQuery: action.keyword,
      };
    case "filterByHabitat":
      return {
        ...state,
        habitatFilter: action.habitat,
      };
    case "filterByState":
      return {
        ...state,
        stateFilter: action.state,
      };
    default:
      throw new Error();
  }
}

function getHabitatsAndStates(frogs: Frog[]) {
  const map: {
    habitats: any;
    states: any;
  } = {
    habitats: {},
    states: {},
  };
  frogs.forEach((frog) => {
    frog.states.map((state) => {
      map.states[state] = true;
    });
    frog.habitats.map((habitat) => {
      map.habitats[habitat] = true;
    });
  });

  return {
    habitats: Object.keys(map.habitats),
    states: Object.keys(map.states),
  };
}

function filterFrogs(
  frogs: Frog[],
  searchQuery: string,
  habitatFilter: string,
  stateFilter: string
) {
  let filtered = frogs;

  if (searchQuery.length > 0) {
    filtered = filtered.filter(
      (frog) =>
        frog.scientificName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        frog.commonNames
          .join("")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
  }

  if (stateFilter.length > 0) {
    filtered = filtered.filter((frog) => frog.states.includes(stateFilter));
  }

  if (habitatFilter.length > 0) {
    filtered = filtered.filter((frog) => frog.habitats.includes(habitatFilter));
  }
  return filtered;
}
