import { Box, BoxProps, Flex, Image, Link, Text } from "@chakra-ui/core";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BodyText from "./BodyText";
import HeaderText from "./HeaderText";

interface Props {
  imageAlignment: "Left" | "Right";
  description: string;
  title?: string;
  image?: string;
  linkLabel?: string;
  linkUrl?: string;
}

const HorizontalContent: React.FC<Props & BoxProps> = ({
  title,
  description,
  image,
  imageAlignment,
  linkLabel,
  linkUrl,
  ...props
}) => {
  const imageOrder = imageAlignment === "Left" ? 0 : 2;
  return (
    <Box {...props}>
      <Flex
        flexDirection={["column", "column", "column", "row", "row",]}
        alignItems="flex-start"
        
      >
        {image && (
          <Image
            order={[0, 0, imageOrder, imageOrder]}
            width={"100%"}
            maxWidth={["800px", "800px", "800px", "360px", "480px"]}
            mr={imageAlignment === "Left" ? [0, 0, 0, 12, 24] : 0}
            ml={imageAlignment === "Left" ? 0 : [0, 0, 0, 12, 24]}
            mb={10}
            src={image}
          />
        )}
        <Box 
          flex={1}
          maxWidth={"800px"}
          mb={10}
        >
          {title && <HeaderText title={title} />}
          <BodyText dangerouslySetInnerHTML={{__html: description}}></BodyText>
          {linkUrl && (
            <Flex mt={5} alignItems="center">
              <Link
                fontWeight="semibold"
                fontSize="2xl"
                color="primary.orange"
                href={linkUrl}
                _hover={{ mr: 3, textDecor: "underline" }}
              >
                {linkLabel}
              </Link>
              <Text color="primary.orange" fontSize="xl" ml={3} as="i">
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Text>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default HorizontalContent;
