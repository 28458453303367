import { Box, BoxProps } from "@chakra-ui/core";
import React from "react";

interface Props {}

const BodyText: React.FC<Props & BoxProps> = ({ children, ...props }) => {
  return (
    <Box className="markdown-text" whiteSpace="pre-line" lineHeight={1.3} fontSize="22px" {...props}>
      {children}
    </Box>
  );
};

export default BodyText;
