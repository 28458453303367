import { Box, BoxProps, Flex } from "@chakra-ui/core";
import React from "react";

interface Props {}

export const Table: React.FC<Props & BoxProps> = ({ children, ...props }) => {
  return (
    <Box width="100%" fontSize={["md", "lg", "xl", "xl"]} {...props}>
      {children}
    </Box>
  );
};

export const TableHeader: React.FC<BoxProps> = props => {
  return (
    <TableRow
      color="black"
      bg="primary.yellow"
      roundedTopLeft="lg"
      roundedTopRight="lg"
      borderColor="primary.yellow"
      {...props}
    />
  );
};

export const TableRow: React.FC<Props & BoxProps> = props => {
  return <Flex bg="white" {...props} />;
};

export const TableHeaderCell: React.FC<Props & BoxProps> = props => {
  return <TableCell {...props} borderWidth={0} />;
};

export const TableCell: React.FC<Props & BoxProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      borderWidth={1}
      borderColor="bg.gray"
      py={[1, 1, 3, 3]}
      px={[2, 2, 4, 4]}
      {...props}
    >
      {children}
    </Box>
  );
};
