import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Infographic from "../components/Infographic";
import Information from "../components/Information";
import InstructionRow from "../components/InstructionRow";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import CodeSnippet from "./CodeSnippet";
import CustomComponent from "./CustomComponent";
import FAQ from "./FAQ";
import FrogIDWeekHero from "./FrogIDWeekHero";
import RichText from "./RichText";
import VideoPlayer from "./VideoPlayer";

interface Props {
  contentfulPage: any;
}

const Page: React.FC<Props> = ({ contentfulPage }) => {
  return (
    <Layout>
      <SEO
        title={contentfulPage.metaTitle}
        description={contentfulPage.metaDescription}
      />

      {contentfulPage.sections.map((section: any) => {
        switch (section.__typename) {
          case "ContentfulHero":
            return <Hero key={section.id} {...section} />;
          case "ContentfulFrogIdWeek":
            return <FrogIDWeekHero key={section.id} {...section} />;
          case "ContentfulHeader":
            return <Header key={section.id} {...section} />;
          case "ContentfulInstructionRow":
            return <InstructionRow key={section.id} {...section} />;
          case "ContentfulInformation":
            return <Information key={section.id} {...section} />;
          case "ContentfulInfographic":
            return <Infographic key={section.id} {...section} />;
          case "ContentfulCodeSnippet":
            return <CodeSnippet key={section.id} {...section} />;
          case "ContentfulRichTextBlock":
            return <RichText key={section.id} {...section} />;
          case "ContentfulVideoPlayer":
            return <VideoPlayer key={section.id} {...section} />;
          case "ContentfulCustomComponent":
            return <CustomComponent key={section.id} {...section} />;
          case "ContentfulFaq":
            return <FAQ key={section.id} {...section} />;
          default:
            return null;
        }
      })}
    </Layout>
  );
};

export default Page;
