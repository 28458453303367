import { Button, ButtonProps } from "@chakra-ui/core";
import React from "react";

const PrimaryButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      color="black"
      size="lg"
      _hover={{ bg: "primary-orange" }}
      bg="primary.yellow"
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
