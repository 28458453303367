import { Flex, Spinner } from "@chakra-ui/core";
import React from "react";
import { useData } from "../hooks/useData";
import { Box } from "@chakra-ui/core";

interface Props {}

const FrogIDOneMillionthFrog: React.FC<Props> = () => {
  // prepend urls with "http://localhost:3000" to communicate with local rails server
  const milestoneUrl = "/api/user/milestone/millionthfrog"; // use in development

  const { data: milestoneData } = useData(milestoneUrl);

  if (milestoneData == null) return <Spinner m={10} color="primary.orange" />;
  const {
    capture_id,
    user_name,
  } = milestoneData[milestoneUrl];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={["column", "column", "row", "row"]}
      flexWrap="wrap"
      my={12}
    >
      <Box
        m={3}
        minWidth="20rem"
        rounded="lg"
        bg="primary.black"
        color="white"
        py={6}
        px={10}
      >
        <Box mb={3} lineHeight={1.0} fontSize="2xl" color="primary.green">
          Capture: {capture_id}
        </Box>
        <Box mb={3} lineHeight={1.0} fontSize="2xl" color="primary.green">
          User: {user_name}
        </Box>
        <Box lineHeight={1.0} fontSize="6xl" fontWeight="bold" color="primary.yellow">
          1,000,000
        </Box>
      </Box>
    </Flex>
  );
};

export default FrogIDOneMillionthFrog;
