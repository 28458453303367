import { Box, Image, Link } from "@chakra-ui/core";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import React from "react";
import theme from "../gatsby-plugin-chakra-ui/theme";

interface Props {
  color?: string;
  content?: {
    json: any;
  };
}

const ContentfulRichText: React.FC<Props> = ({ content }) => {
  if (content == null) return null;
  return <>{documentToReactComponents(content.json, options)}</>;
};

export default ContentfulRichText;

const options = {
  renderMark: {
    [MARKS.CODE]: code => {
      const html = `<style>
        .button-image {
          display: inline;
          height: 50px;
          margin-right: 10px;
        }
        .button-cta {
          display: inline-block;
          margin: 10px;
          border-radius: 5px;
          font-size: 18px;
          font-weight: 600;
          padding: 10px 25px;
          background-color: ${theme.colors.primary.yellow};
          color: black;
        }
      </style>${code}`;
      return (
        <Box color="gray.800">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </Box>
      );
    }
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Box lineHeight={1.0} mb={4} fontSize="5xl" fontWeight="semibold" as="h1">
        {children}
      </Box>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Box lineHeight={1.0} mb={4} fontSize="4xl" fontWeight="semibold" as="h2">
        {children}
      </Box>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Box lineHeight={1.0} mb={4} fontSize="3xl" fontWeight="semibold" as="h3">
        {children}
      </Box>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <Box fontSize="xl" ml={6} as="ul">
        {children}
      </Box>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <Box fontSize="xl" ml={6} as="ol">
        {children}
      </Box>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <Box as="li">
        <Box>{children}</Box>
      </Box>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Box whiteSpace="pre-line" fontSize="22px" mb={4}>
        {children}
      </Box>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const src = node.data.target.fields?.file?.["en-US"].url;
      if (src == null) return null;

      return <Image src={src} />;
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link color="primary.orange" href={node.data.uri}>
        {children}
      </Link>
    )
  }
};
