import { Box, Spinner } from "@chakra-ui/core";
import { css, Global } from "@emotion/core";
import React, { useEffect } from "react";
import useScript from "../hooks/useScript";

interface Props {}

const DataVizMap: React.FC<Props> = () => {
  const loaded = useScript(
    "https://d2pifd398unoeq.cloudfront.net/static/js/embed.js"
  );
  useEffect(() => {
    if (loaded) {
      (window as any).smuCreateEmbed("#dataVizMap");
    }
  }, [loaded]);

  return (
    <Box id="dataVizMap">
      <Global styles={styles} />
      <Spinner color="primary.orange" />
    </Box>
  );
};

export default DataVizMap;

const styles = css`
  .smu-root h1 {
    font-size: 46px;
    margin-bottom: 15px;
    line-height: 1.0;
  }
  .smu-root h3 {
    font-size: 16px;
  }
  .smu-root h4 {
    font-size: 14px;
  }
  .smu-root button {
    font-size: 22px;
  }
  .smu-root ul li {
    font-size: 14px;
  }
  .smu-root .fa-download {
    font-size: 22px;
    margin-left: 18px
  }
  .smu-root .fa-download + span {
    margin: 10px 18px;
  }
`;
