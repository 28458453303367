import { Spinner } from "@chakra-ui/core";
import React from "react";
import { useData } from "../hooks/useData";
import LeaderboardTable from "./FrogIDWeekLeaderboardTable";

interface Props {}

const FrogIDWeekTopFroggers2022: React.FC<Props> = () => {
  const url = "/api/user/leaderboards/frogid_week_2022";
  const { data } = useData(url);

  if (data == null) return <Spinner m={10} color="primary.orange" />;
  const { top_users } = data[url];

  return <LeaderboardTable data={top_users} />;
};

export default FrogIDWeekTopFroggers2022;
