import { Box, BoxProps, Image, Link, Text } from "@chakra-ui/core";
import React from "react";
import BodyText from "./BodyText";
import PrimaryButton from "./PrimaryButton";

interface Props {
  title: string;
  description: string;
  image?: string;
  buttonLabel?: string;
  buttonUrl?: string;
}

const VerticalContent: React.FC<Props & BoxProps> = ({
  title,
  description,
  image,
  buttonLabel,
  buttonUrl,
  ...props
}) => {
  return (
    <Box p={8} textAlign="center" {...props}>
      <Text fontWeight="semibold" fontSize="2xl" as="h3">
        {title}
      </Text>
      {image && <Image mt={5} mx="auto" src={image} />}
      <BodyText mt={5} dangerouslySetInnerHTML={{__html: description}}></BodyText>
      {buttonUrl && (
        <Link
          display="block"
          mt={8}
          _hover={{ textDecor: "none" }}
          href={buttonUrl}
        >
          <PrimaryButton>{buttonLabel}</PrimaryButton>
        </Link>
      )}
    </Box>
  );
};

export default VerticalContent;
