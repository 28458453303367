import { Box, BoxProps, Flex, Image } from "@chakra-ui/core";
import React from "react";
import Container from "./Container";
import ContentfulRichText from "./ContentfulRichText";

type TextAlignProperty = "left" | "center" | "right";

interface Props {
  content: any;
  alignment: string;
  paddingTop: number;
  paddingBottom: number;
  backgroundColor: string;
  image?: {
    fixed: {
      src: string;
    };
  };
}

const RichText: React.FC<Props & BoxProps> = ({
  content,
  image,
  alignment,
  paddingTop,
  paddingBottom,
  backgroundColor,
  ...props
}) => {
  let justifyContent = "flex-start";
  let textAlign: TextAlignProperty = "left";

  switch (alignment) {
    case "Center":
      justifyContent = "center";
      textAlign = "center";
      break;
    case "Right":
      justifyContent = "flex-end";
      textAlign = "right";
  }

  const pt = paddingTop != null ? paddingTop : 24;
  const pb = paddingBottom != null ? paddingBottom : 24;
  const bg = backgroundColor != null ? backgroundColor : "white";

  return (
    <Box
      pt={[pt / 2, pt / 2, pt, pt]}
      pb={[pb / 2, pb / 2, pb, pb]}
      bg={bg}
      {...props}
    >
      <Container>
        <Flex
          width="100%"
          justifyContent={image ? "flex-start" : justifyContent}
          alignItems="center"
          textAlign={textAlign}
          flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
        >
          {image && (
            <Image
              mr={[0, 0, 20, 20]}
              mb={[10, 10, 0, 0]}
              width="350px"
              height="auto"
              src={image.fixed.src}
            />
          )}
          <Box {...props}>
            <ContentfulRichText content={content} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default RichText;
