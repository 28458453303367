import { Box, Flex, Spinner, Text } from "@chakra-ui/core";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useData } from "../hooks/useData";
import Avatar from "./Avatar";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "./DataTable";
import TableStat from "./TableStat";

interface Props {}
interface School {
  id: string;
  name: string;
  desc: string;
  avatar: string;
  published_captures_count: number;
}

const SchoolsLeaderboard: React.FC<Props> = () => {
  const url = "/api/user/leaderboards";
  const { data } = useData(url);

  if (data == null) return <Spinner m={10} color="primary.orange" />;

  const { schools } = data[url];

  return (
    <Table my={12}>
      <TableHeader>
        <TableHeaderCell width={[16, 16, 24, 32]}>Rank</TableHeaderCell>
        <TableHeaderCell width={[16, 16, 24, 32]}></TableHeaderCell>
        <TableHeaderCell textAlign="left" flex="1">
          Name
        </TableHeaderCell>
        <TableHeaderCell width={["5rem", "5rem", "10rem", "18rem"]}>
          Captures Count
        </TableHeaderCell>
      </TableHeader>
      {schools.map((school: School, index: number) => (
        <TableRow key={school.id}>
          <TableCell width={[16, 16, 24, 32]}>{index + 1}</TableCell>
          <TableCell width={[16, 16, 24, 32]}>
            <Avatar width="40px" height="40px" src={school.avatar} />
          </TableCell>
          <TableCell textAlign="left" flex="1">
            <Box fontSize="xl" fontWeight="semibold">
              <Flex alignItems="center">
                {school.name}
                {index === 0 && (
                  <Text ml={2} fontSize="20px" color="primary.orange">
                    <FontAwesomeIcon icon={faMedal} />
                  </Text>
                )}
              </Flex>
            </Box>
            <Box fontSize="md">
              <Text>{school.desc}</Text>
            </Box>
          </TableCell>
          <TableCell width={["5rem", "5rem", "10rem", "18rem"]}>
            <TableStat count={school.published_captures_count} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default SchoolsLeaderboard;
