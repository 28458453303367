import { Box, BoxProps } from "@chakra-ui/core";
import numeral from "numeral";
import React from "react";

const HighlightStat: React.FC<{ title: string; count: string } & BoxProps> = ({
  title,
  count,
  ...props
}) => {
  return (
    <Box
      m={3}
      minWidth="20rem"
      rounded="lg"
      bg="primary.black"
      color="white"
      py={6}
      px={10}
      {...props}
    >
      <Box mb={3} lineHeight={1.0} fontSize="2xl" color="primary.green">
        {title}
      </Box>
      <Box lineHeight={1.0} fontSize="6xl" fontWeight="bold" color="primary.yellow">
        {numeral(count).format("0,0")}
      </Box>
    </Box>
  );
};

export default HighlightStat;
