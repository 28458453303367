import { Box, BoxProps, Flex, Text } from "@chakra-ui/core";
import React from "react";
import BodyText from "../components/BodyText";
import VerticalContent from "../components/VerticalContent";

interface Props {
  title: string;
  columns: number;
  description: {
    description: string;
  };
  instructions?: {
    id: string;
    title: string;
    description: {
      childMarkdownRemark: {
        html: string
      }
    };
    buttonLabel: string;
    buttonUrl: string;
    image: {
      fixed: {
        src: string;
      };
    };
  }[];
}

const InstructionRow: React.FC<Props & BoxProps> = ({
  title,
  description,
  instructions,
  columns,
  ...props
}) => {
  let width = "25%";
  switch (columns) {
    case 2:
      width = "28rem";
      break;
    case 3:
      width = "33%";
      break;
    case 4:
      width = "25%";
      break;
  }
  return (
    <Box mx="auto" width="90%" py={24} textAlign="center" {...props}>
      <Box mb={10}>
        <Text
          lineHeight={1}
          mb={6}
          fontWeight="semibold"
          fontSize="5xl"
          as="h2"
        >
          {title}
        </Text>
        {description && <BodyText>{description.description}</BodyText>}
      </Box>
      <Flex width="100%" flexWrap="wrap" justifyContent="center">
        {instructions?.map(instruction => (
          <VerticalContent
            width={["100%", "100%", "50%", width]}
            key={instruction.id}
            title={instruction.title}
            image={instruction.image.fixed.src}
            description={instruction.description.childMarkdownRemark.html}
            buttonLabel={instruction.buttonLabel}
            buttonUrl={instruction.buttonUrl}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default InstructionRow;
