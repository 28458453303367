import { Box, BoxProps } from "@chakra-ui/core";
import React from "react";

interface Props {
  paddingTop: number;
  paddingBottom: number;
  backgroundColor: string;
}

const StyledBox: React.FC<Props & BoxProps> = ({
  paddingTop,
  paddingBottom,
  backgroundColor,
  ...props
}) => {
  const pt = paddingTop != null ? paddingTop : 24;
  const pb = paddingBottom != null ? paddingBottom : 24;
  const bg = backgroundColor != null ? backgroundColor : "transparent";

  return (
    <Box
      pt={[pt / 2, pt / 2, pt, pt]}
      pb={[pb / 2, pb / 2, pb, pb]}
      bg={bg}
      {...props}
    />
  );
};

export default StyledBox;
