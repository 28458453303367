import { Text } from "@chakra-ui/core";
import numeral from "numeral";
import React from "react";

const TableStat: React.FC<{ count: number }> = ({ count }) => {
  return (
    <Text fontSize={["xl", "xl", "2xl", "2xl"]} fontWeight="semibold">
      {numeral(count).format("0,0")}
    </Text>
  );
};

export default TableStat;
