import { Box, Flex, Spinner, Text } from "@chakra-ui/core";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useData } from "../hooks/useData";
import Avatar from "./Avatar";
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "./DataTable";
import TableStat from "./TableStat";

interface Props {}
interface User {
  id: string;
  public_name: string;
  group_name?: string;
  avatar: string;
  published_captures_count: number;
}

const FrogIDProjectTopFroggers: React.FC<Props> = () => {
  const url = "/api/user/leaderboards";
  const { data } = useData(url);

  if (data == null) return <Spinner m={10} color="primary.orange" />;

  const { users } = data[url];

  return (
    <Table my={12}>
      <TableHeader>
        <TableHeaderCell width={[16, 16, 24, 32]}>Rank</TableHeaderCell>
        <TableHeaderCell width={[16, 16, 24, 32]}></TableHeaderCell>
        <TableHeaderCell textAlign="left" flex="1">
          Name
        </TableHeaderCell>
        <TableHeaderCell width={["5rem", "5rem", "10rem", "18rem"]}>
          Captures Count
        </TableHeaderCell>
      </TableHeader>
      {users.map((user: User, index: number) => (
        <TableRow key={user.id}>
          <TableCell width={[16, 16, 24, 32]}>{index + 1}</TableCell>
          <TableCell width={[16, 16, 24, 32]}>
            <Avatar width="40px" height="40px" src={user.avatar} />
          </TableCell>
          <TableCell textAlign="left" flex="1">
            <Flex alignItems="center">
              {user.public_name}{" "}
              {index === 0 && (
                <Text ml={2} fontSize="25px" color="primary.orange">
                  <FontAwesomeIcon icon={faMedal} />
                </Text>
              )}
            </Flex>
            {user.group_name && (
              <Flex fontSize={["xs", "xs", "md", "md"]} mt={2}>
                <Box
                  fontWeight="semibold"
                  rounded="lg"
                  bg="bg.gray"
                  px={2}
                  py={1}
                >
                  {user.group_name}
                </Box>
              </Flex>
            )}
          </TableCell>
          <TableCell width={["5rem", "5rem", "10rem", "18rem"]}>
            <TableStat count={user.published_captures_count} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default FrogIDProjectTopFroggers;
