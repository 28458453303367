import { Box, BoxProps, Text } from "@chakra-ui/core";
import React from "react";
import BodyText from "../components/BodyText";

interface Props {
  title: string;
  caption?: string;
}

const HeaderText: React.FC<Props & BoxProps> = ({
  title,
  caption,
  ...props
}) => {
  return (
    <Box color="text.gray" {...props}>
      <Text lineHeight={1} fontWeight="semibold" fontSize="4xl" as="h2" mb={4}>
        {title}
      </Text>
      {caption && <BodyText>{caption}</BodyText>}
    </Box>
  );
};

export default HeaderText;
