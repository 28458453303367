import { Box, Flex, Image } from "@chakra-ui/core";
import React from "react";
import Container from "./Container";
import ContentfulRichText from "./ContentfulRichText";
import PrimaryHeader from "./PrimaryHeader";
import StyledBox from "./StyledBox";
import VerticalContent from "./VerticalContent";

interface Props {
  title: string;
  description: {
    description: string;
  };
  image?: {
    fixed: {
      src: string;
    };
  };
  callToAction?: any;
  instructionRow?: {
    id: string;
    title: string;
    description: {
      description: string;
    };
    buttonLabel: string;
    buttonUrl: string;
    image: {
      fixed: {
        src: string;
      };
    };
  }[];
}

const Hero: React.FC<Props> = ({
  title,
  description,
  image,
  callToAction,
  instructionRow
}) => {
  return (
    <Box
      py={[12, 12, 24, 24]}
      position="relative"
      bg="primary.black"
      color="white"
    >
      <Container>
        <Flex
          flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
          alignItems="center"
          justifyContent="flex-start"
          textAlign={image == null ? "center" : "left"}
        >
          <Box flex={1}>
            <PrimaryHeader
              mx="auto"
              maxWidth="50rem"
              title={title}
              caption={description.description}
            />
            {callToAction && (
              <StyledBox {...callToAction}>
                <ContentfulRichText {...callToAction} />
              </StyledBox>
            )}
          </Box>
          {image && <Image width="md" src={image.fixed.src} />}
        </Flex>
      </Container>
      {instructionRow && (
        <Flex py={12} flexWrap="wrap" justifyContent="center">
          {instructionRow.map(instruction => (
            <VerticalContent
              width="450px"
              key={instruction.id}
              title={instruction.title}
              image={instruction.image.fixed.src}
              description={instruction.description.description}
              buttonLabel={instruction.buttonLabel}
              buttonUrl={instruction.buttonUrl}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default Hero;
