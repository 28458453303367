import React from "react";
import CloudinaryVideoPlayer from "./CloudinaryVideoPlayer";
import Container from "./Container";
import YouTubeVideoPlayer from "./YouTubeVideoPlayer";

interface Props {
  title: string;
  source: string;
}

const VideoPlayer: React.FC<Props> = ({ source, ...props }) => {
  switch (source) {
    case "Cloudinary":
      return (
        <Container>
          <CloudinaryVideoPlayer {...props} />
        </Container>
      );
    case "YouTube":
      return (
        <Container>
          <YouTubeVideoPlayer {...props} />
        </Container>
      );
    default:
      return null;
  }
};

export default VideoPlayer;
